import { Link } from 'gatsby';
import React from 'react';
// import logo from '@app/images/logo.png';
import logo from '@app/images/contesty/android-chrome-512x512.png';

import './Navbar.scss';

export const Navbar = () => {
  return (
      <div className="navbar navbar-default navbar-lg app-navbar">
        <div className="container">
          <div className="navbar-header">
            <Link to="/" className="navbar-brand">
              <img alt="taneczny kalendarz logo" src={ logo }/>
            </Link>
          </div>

          <div className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="/" id="navbar-goto-index">Strona główna</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
  )
}
